import React from 'react';
import PropTypes from 'prop-types';
import AuthorsLandingPage from '@cleverrealestate/clever-components-v2/dist/components/AuthorsLandingPage';
import {
  useUserIPGrabber,
} from '@cleverrealestate/clever-helpers';
import SEO from '../components/Seo';
import Layout from '../components/layout';

const AuthorsLandingTemplate = ({ pageContext }) => {
  useUserIPGrabber();
  const {
    authors,
    breadcrumbs,
    title,
    description,
    metaDescription,
    metaTitle,
  } = pageContext;

  return (
    <Layout
      colorTransitionStart={-1}
      shadowTransitionStart={50}
      colorTransitionLength={1}
      shadowTransitionLength={125}
      isWhite
    >
      <SEO
        pathname="/authors/"
        title={metaTitle}
        description={metaDescription}
        breadcrumbs={breadcrumbs}
      />
      <AuthorsLandingPage
        breadcrumbs={breadcrumbs}
        title={title}
        content={description}
        authors={authors}
      />
    </Layout>
  );
};

AuthorsLandingTemplate.propTypes = {
  pageContext: PropTypes.any,
};

AuthorsLandingTemplate.defaultProps = {
  pageContext: {},
};

export default AuthorsLandingTemplate;
